/* eslint-disable jsx-a11y/control-has-associated-label */
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faTiktok, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import './footer.scss';

function Footer() {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  return (
    <footer className="footer">
      <div className="map-container">
        <iframe
          title="Location of Zov Furniture LLC"
          className="google-map"
          allowFullScreen=""
          style={{ border: 0 }}
          aria-hidden="false"
          src={`https://www.google.com/maps/embed/v1/place?key=${googleMapsApiKey}&q=24.480538197575605, 54.357646964641795`}
        />
      </div>
      <div className="contact-info">
        <div className="contact-item">
          <LocationOnIcon />
          <div>
            <p>Grand Floor NBH Tower, (Al Muhairy Centre) – Zayed The First Street</p>
            <p>Al Hisn - W4 – Abu Dhabi - UAE</p>
          </div>
        </div>
        <div className="contact-item">
          <AccessTimeIcon className="icon" />
          <div>
            <p>10:00AM-8:00PM Everyday</p>
          </div>
        </div>
        <div className="contact-item" style={{ marginBottom: '1rem' }}>
          <EmailIcon />
          <div>
            <a href="mailto:info@zov.ae">info@zov.ae</a>
          </div>
          <PhoneIcon />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a href="tel:+971509636346">+971509636346</a>
            <a href="tel:+971522518853">+971522518853</a>
          </div>
        </div>
        <div className="contact-item">
          <div>
            <Link to="/privacy-policy">ZOV FURNITURE L.L.C. – All Rights Reserved © 2024</Link>
          </div>
        </div>
      </div>
      <div className="social-media">
        <a href="https://www.instagram.com/zov.furniture.llc/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.tiktok.com/@zov.furniture.llc" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
        <a href="https://www.linkedin.com/company/zov-furniture-l-l-c/about/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} size="2x" />
        </a>
        {/* Bouton WhatsApp */}
        <a
          href="https://wa.me/971522518853?text=Hello, i need informations about Zov products."
          className="whatsapp-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsAppIcon fontSize="large" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
